<!-- use tags -->
export interface Input {
  class?: string;
  compact?: boolean;
}
<hr class=["divider", input.compact && "divider--compact", input.class]>

<style.scss>
  .divider {
    background: light-dark($color-neutral-30, $color-neutral-70);
    border: none;
    height: 1px;
    margin: $spacing-12 0;

    &--compact {
      margin: 0;
    }
  }
</style>
