<!-- use tags -->
export interface Input {
  level: "error" | "success" | "information" | "warning";
  onDismiss?: () => void;
  renderBody: Marko.Body;
}

<div class=[
  "notification",
  `notification--${input.level}`,
  input.onDismiss && `notification--dismissable`,
]>
  <div class="notification__icon">
    <sb-icon icon=input.level title=`Notification level ${input.level}`/>
  </div>
  <div>
    <${input.renderBody}/>
  </div>
  <if=input.onDismiss>
    <button
      aria-label="Dismiss"
      class="notification__dismiss"
      onClick=input.onDismiss
    >
      <sb-icon icon="x-mark"/>
    </button>
  </if>
</div>

<style.scss>
  .notification {
    @include body3;

    align-items: start;
    border: 1px solid;
    border-radius: $radius-s;
    display: grid;
    font-weight: $font-weight-medium;
    grid-gap: $spacing-12;
    grid-template-columns: 1.5em 1fr;
    padding: $spacing-16;

    &__dismiss {
      background: none;
      border: none;
    }

    &--dismissable {
      grid-template-columns: 1.5em 1fr 1.5em;
    }

    &--error {
      background: light-dark($color-status-error-05, $color-neutral-85);
      border-color: light-dark($color-status-error-40, $color-status-error-30);
    }

    &--error &__icon {
      color: light-dark($color-status-error-40, $color-status-error-30);
    }

    &--information {
      background: light-dark($color-status-information-05, $color-neutral-85);
      border-color: light-dark(
        $color-status-information-40,
        $color-status-information-30
      );
    }

    &--information &__icon {
      color: light-dark(
        $color-status-information-40,
        $color-status-information-30
      );
    }

    &--success {
      background: light-dark($color-status-success-05, $color-neutral-85);
      border-color: light-dark(
        $color-status-success-40,
        $color-status-success-30
      );
    }

    &--success &__icon {
      color: light-dark($color-status-success-40, $color-status-success-30);
    }

    &--warning {
      background: light-dark($color-status-warning-05, $color-neutral-85);
      border-color: light-dark(
        $color-status-warning-40,
        $color-status-warning-30
      );
    }

    &--warning &__icon {
      color: light-dark($color-status-warning-40, $color-status-warning-30);
    }
  }
</style>
