class {
  onMount() {
    document.querySelectorAll("form").forEach((form) => {
      // no need to undo this, because we use native form submits
      form.addEventListener("submit", (e) => {
        const { classList } = document.documentElement;
        if (classList.contains("is-submitting")) {
          e.preventDefault();
        }
        classList.add("is-submitting");
      });
    });
  }
}
style.scss {
  .is-submitting {
    button[type="submit"] {
      background: light-dark($color-neutral-20, $color-neutral-90);
      color: light-dark($color-neutral-50, $color-neutral-60);
      cursor: not-allowed;
    }
  }
}
